import React, { useEffect, useRef, useState } from 'react';

import './index.css'

const NotFound = (props) => {


    return (
        <div className='wrapper'>
            <h2 className='subtitle'>This page does not exist.</h2>
        </div>

    )

}

export default NotFound;